<template>
  <div class="edu-frame">
    <edu-nav-bar title="朋友圈" :show="show" @showPopup="show=true"
                 @hidePopup="show=false"></edu-nav-bar>
    <div @click="$router.push({ name: 'pyqpost'})" style="border-radius: 50%;position: absolute;bottom: 8%;right: 5%;width: 50px;height: 50px;background: #39a9ed;z-index: 999;display: flex;justify-content: center;align-items: center;">
      <van-icon name="photograph" size="30px" style="margin-left: 3px;" color="white"/>
    </div>
    <div class="content-frame">
      <van-list
          class="activity-list"
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
      >
        <div v-for="(item, index) in list" :key="index">
          <div style="background-color: white;padding: 10px;display: flex;position: relative;">

            <div style="width: 40px;height:40px; border-radius: 3px;overflow: hidden;margin-right: 10px;">
              <img style="width: 40px;height:40px;" :src="item.imgUrl +'?date='+ (new Date().valueOf())" alt="">
            </div>
            <div style="font-size: 14px;flex: 1;">
              <div style="margin-bottom: 8px;color: rgb(94, 113, 152);">{{ item.fullName }}</div>
              <div style="margin-bottom: 8px;">{{ item.comment }}</div>
              <div style="width: 100%;margin-bottom: 8px;">
                <div style="display: flex;flex-wrap: wrap;">

                  <div v-if="item.pic1!=null&&item.pic1!=''" class="pyqimg"><img style="width: 100%;" :src="item.pic1" alt=""></div>
                  <div v-if="item.pic2!=null&&item.pic2!=''" class="pyqimg"><img style="width: 100%;" :src="item.pic2" alt=""></div>
                  <div v-if="item.pic3!=null&&item.pic3!=''" class="pyqimg"><img style="width: 100%;" :src="item.pic3" alt=""></div>
                  <div v-if="item.pic4!=null&&item.pic4!=''" class="pyqimg"><img style="width: 100%;" :src="item.pic4" alt=""></div>
                  <div v-if="item.pic5!=null&&item.pic5!=''" class="pyqimg"><img style="width: 100%;" :src="item.pic5" alt=""></div>
                  <div v-if="item.pic6!=null&&item.pic6!=''" class="pyqimg"><img style="width: 100%;" :src="item.pic6" alt=""></div>
                  <div v-if="item.pic7!=null&&item.pic7!=''" class="pyqimg"><img style="width: 100%;" :src="item.pic7" alt=""></div>
                  <div v-if="item.pic8!=null&&item.pic8!=''" class="pyqimg"><img style="width: 100%;" :src="item.pic8" alt=""></div>
                  <div v-if="item.pic9!=null&&item.pic9!=''" class="pyqimg"><img style="width: 100%;" :src="item.pic9" alt=""></div>

                </div>

              </div>
              <div style="display: flex;align-items: center;margin-bottom: 8px;">
                <div style="flex: 1;">{{ item.billdate | filterDate }}</div>
                <div style="margin-top: 5px;" @click="toComment(item)"><img style="width: 20px;" src="../../../statics/images/comment.png" alt=""></div>
                <div v-if="item.custId == cust.custId" style="margin-left: 10px;" @click="deleteComment(item)">删除</div>
              </div>
              <div style="background: #f7f7f7;">
                <div v-for="(child, cIndex) in item.childs" :key="'child_'+cIndex" style="padding: 4px 8px;">{{ child.fullName }}：{{ child.comment }}</div>
              </div>
            </div>
          </div>
          <hr style="border: 0;margin:0;height: 1px;background-color: #ededed;"/>
        </div>

      </van-list>
    </div>

    <van-popup position="bottom" v-model="showPopup" style="width: 100vw;">
      <div style="width: 100vw;height: 48px">
        <van-field
            ref="comment"
            v-model="comment"
            center
            label=""
            placeholder="请输入您的评论"
             style="padding: 8px;"
            :autofocus="true"
        >
          <template #button>
            <van-button :disabled="comment==null||comment==''" :loading="submitting" size="small" type="primary" @click="submitComment">发送</van-button>
          </template>
        </van-field>
      </div>
    </van-popup>
  </div>
</template>
<script>
import EduNavBar from "@/components/VolunteerEduNavBar.vue";
import {List, Icon, Popup, Field, Button, Dialog} from "vant";
import Tools from "@/api/Tools";
import VolunteerApi from "@/api/Volunteer";
import XEUtils from "xe-utils";
export default {
  components: {
    EduNavBar,
    VanList: List,
    VanPopup: Popup,
    VanField: Field,
    VanButton: Button,
    VanIcon: Icon
  },
  filters: {
    filterDate(date) {
      return XEUtils.toDateString(XEUtils.toStringDate(date, 'yyyyMMddHHmmss'), 'yyyy-MM-dd HH:mm:ss')
    }
  },
  data() {
    return {
      cust: Tools.getCurCust(),
      show: false,
      loading: false,
      finished: false,
      pageInfo: { page: 1, pageSize: 15, condition: {} },
      list: [],
      showPopup: false,
      comment: null,
      curPyq: null,
      submitting: false
    }
  },
  methods: {
    onLoad() {
      this.loading = true
      this.pageInfo.condition.custId = this.cust.custId
      this.pageInfo.condition.status = 1
      VolunteerApi.getVolunteerPyqs(this.pageInfo).then(response => {
        this.loading = false
        console.log(response)
        for(var i = 0; i < response.res.list.length; i++) {
          this.list.push(response.res.list[i])
        }
        if (response.res.isLastPage == 1) {
          this.finished = true
        } else {
          this.pageInfo.page = this.pageInfo.page + 1
        }
      }).catch(() => { this.loading = false;this.finished = true })

    },
    submitComment() {
      this.submitting = true
      var volunteerComment = { parentPyqId: this.curPyq.pyqId, comment: this.comment, custId: this.cust.custId, fullName: this.cust.fullName, custCode: this.cust.custCode }
      VolunteerApi.submitPyq(volunteerComment).then(response => {
        this.submitting = false
        this.$toast(response.msg)
        if (response.code == 100) {
          if (this.curPyq.childs==null) {
            this.curPyq.childs = []
          }
          this.curPyq.childs.push(response.res)
          this.showPopup=false
        }
      }).catch(() => { this.submitting = false })
    },
    toComment(item) {
      this.showPopup=true;
      setTimeout(()=>{
        this.$refs.comment.focus();
      }, 100)
      this.curPyq=item
    },
    deleteComment(item) {
      var pyqId = item.pyqId
      Dialog.confirm({
        title: '提示',
        message: '是否删除该条朋友圈?',
      }).then(() => {
        VolunteerApi.deletePyqById(pyqId).then(response=> {
          this.$toast(response.msg)
          if (response.code == 100) {
            this.list = []
            this.finished = false
            this.onLoad()
          }
        })
      }).catch(() => {
      });
    }
  },
  mounted() {
    this.onLoad()
  }
}
</script>
<style scoped>
.content-frame {
  height: calc(100vh - 46px);
  overflow: auto;
}
.pyqimg {
  width: calc(33% - 5px);margin-right: 5px;
}
</style>
