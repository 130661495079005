import request from './request'

function getVolunteerPyqs(data) {
    return request('get','/qxapi/edu/volunteerpyq/volunteerpyqs', data)
}
function submitPyq(data) {
    return request('post','/qxapi/edu/volunteerpyq/submitpyqs', data)
}
function deletePyqById(data) {
    return request('delete','/qxapi/edu/volunteerpyq/deletepyqbyid/' + data)
}

export default {
    getVolunteerPyqs,
    deletePyqById,
    submitPyq
}
